// Function to set up the event listener
function setupCalendlyListener() {
  window.addEventListener('message', function(e) {
    // Check if the message is from Calendly
    if (e.data.event && e.data.event.indexOf('calendly') === 0) {
      if (e.data.event === 'calendly.event_scheduled') {
        console.log('Calendly booking completed!');
        
        // Find and click the button
        const button = document.querySelector('.generic-button.submit');
        if (button) {
          button.click();
          console.log('Button clicked');
        } else {
          console.log('Button not found');
        }
        // Add your custom logic here
      }
    }
  });
}

// Function to check if the Calendly iframe exists
function checkForCalendlyIframe() {
  const iframe = document.querySelector('iframe[src*="calendly.com"]');
  if (iframe) {
    console.log('Calendly iframe found');
    setupCalendlyListener();
  } else {
    console.log('Calendly iframe not found, checking again in 1 second');
    setTimeout(checkForCalendlyIframe, 1000);
  }
}

window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

checkForCalendlyIframe();

/////


const DEBUG = false;
// The screen where we should do the calculation and redirect
const REDIRECT_SCREEN_NAME = 'redirector';

window.addEventListener('heyflow-screen-view', (event) => {
    if(DEBUG) console.log('heyflow screen view:', event.detail);
    if(DEBUG) console.log('heyflow screen view:', event);
});

// Define the result grades logic as a constant with button IDs
const results = {
    A: { min: 0, max: 5, btnId: 'A' },   // Grade A (Best Case) <= 5
    C: { min: 6, max: 24, btnId: 'C' },  // Grade C (Base Case) 6-24
    F: { min: 25, max: Infinity, btnId: 'F' }  // Grade F (Worst Case) > 24
};


// Function to calculate the grade based on the sum
function calculateGrade(sum, results) {
    if (sum <= results.A.max) {
        return { grade: 'A', btnText: results.A.btnText };
    } else if (sum >= results.C.min && sum <= results.C.max) {
        return { grade: 'C', btnText: results.C.btnText };
    } else if (sum > results.C.max) {
        return { grade: 'F', btnText: results.F.btnText };
    }
}

window.addEventListener('heyflow-button-click', (customEventName,customEventLabel) => {
    // console.log('heyflow-button-click:', event);
    if(DEBUG) console.log('heyflow-button-click:', customEventName);
    if(DEBUG) console.log('heyflow-button-click:', customEventLabel);
});

let shadowRoot;
let calculatedGrade = null;

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`).shadowRoot) || 
        document;
});

window.addEventListener('heyflow-change', function myFunction(event) {
    const fields = event.detail.fields;
    if(DEBUG) console.log('event.detail.fields', event.detail.fields);
    let sum = 0;

    fields.forEach(field => {
        if (field.variable && field.variable.startsWith('active')) {
            field.values.forEach(valueObj => {
                const value = parseInt(valueObj.answer, 10);
                if (!isNaN(value)) {
                    sum += value;
                    if(DEBUG) console.log('field.variable', field.variable, 'value', value, 'sum',sum)
                }
            });
        }
    });

    if(DEBUG) console.log('Sum of active variables:', sum);

    const { grade, btnId } = calculateGrade(sum, results);
    calculatedGrade = { grade, btnId }; // Store the calculated grade and button ID
    if(DEBUG) console.log('Calculated Grade:', grade);

    // Store the sum and result in the Heyflow variables
    if (shadowRoot) {
        const sumElement = shadowRoot.querySelector('[data-variable="sum"]');
        const resultElement = shadowRoot.querySelector('[data-variable="result"]');
        if (sumElement) {
            sumElement.value = sum;
        } else {
            console.error('Element with data-variable="sum" not found.');
        }

        if (resultElement) {
            resultElement.value = grade;
        } else {
            console.error('Element with data-variable="result" not found.');
        }
    } else {
        console.error('Shadow root not found.');
    }
});

window.addEventListener('heyflow-screen-view', (event) => {
    if(DEBUG) console.log('heyflow screen view:', event.detail.screenName);

    if (event.detail.screenName === REDIRECT_SCREEN_NAME) {
        if(DEBUG) console.log(`Detected screen: ${REDIRECT_SCREEN_NAME}`);

        // Retrieve the calculated grade from the shadow DOM
        const resultElement = shadowRoot.querySelector('[data-variable="result"]');
        if (resultElement) {
            const calculatedGrade = resultElement.value.trim();
            if(DEBUG) console.log('Retrieved calculated grade:', calculatedGrade);

            // Find and click the button with innerText matching the calculated grade
            const buttons = shadowRoot.querySelectorAll('button');
            let buttonFound = false;

            buttons.forEach(button => {
                if (button.innerText.trim() === calculatedGrade) {
                    if(DEBUG) console.log(`Redirecting by clicking button with innerText: ${calculatedGrade}`);
                    button.click();
                    buttonFound = true;
                }
            });

            if (!buttonFound) {
                console.error(`Button with innerText "${calculatedGrade}" not found.`);
            }
        } else {
            console.error('Element with data-variable="result" not found.');
        }
    }
});

window.addEventListener("heyflow-screen-view", (event) => {
    try {
        // Check if sessionId exists
        const sessionId = Object.values(window.heyflow)?.[0]?.runtime?.sessionId;

        if (sessionId) {
            // Check if notification for this sessionId has already been sent
            const notificationSent = localStorage.getItem(`notificationSent_${sessionId}`);

            if (!notificationSent) {
                // Get the FLOW_ID
                const flowId = Object.values(window.heyflow)?.[0]?.constants?.FLOW_ID;
                const flowTitle = Object.values(window.heyflow)?.[0]?.constants?.TITLE;

                if (flowId) {
                    // Send the request to Zapier using an Image object
                    const newImage = new Image();
                    newImage.src = `https://hooks.zapier.com/hooks/catch/12222056/26voi7t/?event=new_funnel_session&flowId=${flowId}&flowTitle=${flowTitle}`;

                    // Store that notification has been sent for this sessionId
                    localStorage.setItem(`notificationSent_${sessionId}`, "true");
                    console.log("Notification sent via Image and recorded in localStorage.");
                } else {
                    console.log("FLOW_ID not found.");
                }
            } else {
                console.log("Notification already sent for this sessionId.");
            }
        } else {
            console.log("sessionId not found in heyflow-screen-view.");
        }
    } catch (error) {
        console.error("Error accessing sessionId or FLOW_ID:", error);
    }
});

